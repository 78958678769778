


















































































































































































































































































































































































































































































































































































































.paperRules /deep/.el-dialog__body{
  padding-bottom: 0;
}
.paperRules /deep/.el-row{
  margin-bottom: 20px;
}
.paperRules /deep/.el-input__suffix{
  display: flex;
  align-items: center;
}
//.paperRules /deep/.el-input .el-input__count .el-input__count-inner{
//  line-height: 28px;
//}
