.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.dialogBtn button {
  width: 10rem;
}
 /deep/ .el-table__body-wrapper {
  min-height: 360px;
}
.searchbox > div {
  align-items: start;
}
.searchboxItem {
  margin-right: 20px;
}
 /deep/ .el-range-editor .el-range-input {
  position: relative;
  top: -2px;
}
