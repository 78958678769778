.paperRules /deep/ .el-dialog__body {
  padding-bottom: 0;
}
.paperRules /deep/ .el-row {
  margin-bottom: 20px;
}
.paperRules /deep/ .el-input__suffix {
  display: flex;
  align-items: center;
}
